/**
 * @component components/dropdown
 * @description Singleton for controlling dropdowns on the page.
 * @description html structure:
 * button.js-dropdown-control#{idControl}[aria-controls=idDropdown][aria-expanded=false]
 * +
 * ul.dropdown#idDropdown[aria-labelledby="{idControl}"][aria-hidden=true][role="menu"]
 */

class Dropdown {
    /**
     * @returns {string}
     */
    static get CONTROL_CLASS() {
        return '.js-dropdown-control';
    }

    /**
     * @returns {boolean}
     */
    get menuItemFocused() {
        return document.activeElement.matches('[role="menuitem"]');
    }

    /**
     * @returns {boolean}
     */
    get controlFocused() {
        return document.activeElement.matches(Dropdown.CONTROL_CLASS);
    }

    /** */
    constructor() {
        this.activeControl = undefined;
        this.activeTarget = undefined;
        this.menuOptions = undefined;
        this._onClick = this._onClick.bind(this);
        this._onKeyDown = this._onKeyDown.bind(this);
        this._addEventListeners();
    }

    /**
     * @private
     */
    _addEventListeners() {
        document.addEventListener('click', this._onClick);
        document.addEventListener('keydown', this._onKeyDown);
    }

    /**
     * @param {MouseEvent} event
     */
    _onClick(event) {
        const control = event.target;

        if (this.activeControl) {
            this._closeDropdown(this.activeControl);
        }

        if (!control.matches(Dropdown.CONTROL_CLASS)) {
            return;
        }

        if (control.getAttribute('aria-expanded') === 'false') {
            this._openDropdown(control);
            return;
        }

        this._closeDropdown(control);
    }

    /**
     * @param {KeyboardEvent} event
     */
    _onKeyDown(event) {
        if (!this.controlFocused && !this.menuItemFocused) {
            return;
        }

        switch (event.key) {
            case 'Escape':
                this._closeDropdown(this.activeControl);
                break;

            case 'ArrowDown':
                event.preventDefault();
                requestAnimationFrame(() => this._selectNextItem());
                break;

            case 'ArrowUp':
                event.preventDefault();
                requestAnimationFrame(() => this._selectPreviousItem());
                break;

            default:
                break;
        }
    }

    /**
     * @private
     */
    _selectNextItem() {
        if (this.menuItemFocused) {
            const currentIndex = this.menuOptions.indexOf(document.activeElement);
            const nextItem = this.menuOptions[currentIndex + 1];
            if (nextItem) {
                nextItem.focus();
            }
            return;
        }

        if (this.controlFocused) {
            this.menuOptions[0].focus();
        }
    }

    /**
     * @private
     */
    _selectPreviousItem() {
        if (this.menuItemFocused) {
            const currentIndex = this.menuOptions.indexOf(document.activeElement);
            const previousItem = this.menuOptions[currentIndex - 1];
            if (previousItem) {
                previousItem.focus();
            }
            return;
        }

        if (this.controlFocused) {
            this.menuOptions.at(-1).focus();
        }
    }

    /**
     * @param {HTMLElement} control
     * @returns {HTMLElement}
     */
    _getTarget(control) {
        return document.getElementById(control.getAttribute('aria-controls'));
    }

    /**
     * @param {HTMLElement} control
     * @param {HTMLElement} target
     * @returns {object} - a set of dimensions
     */
    _calcInitial(control, target) {
        // initial DOM element values
        const rect = {
            control: control.getBoundingClientRect(),
            target: target.getBoundingClientRect(),
            body: {
                width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
                height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
            },
        };

        return rect;
    }

    /**
     * @param {HTMLElement} control
     * @param {HTMLElement} target
     * @returns {object} offset
     */
    _calcOffset(control, target) {
        const rect = this._calcInitial(control, target);
        const offset = {
            top: undefined,
            left: undefined,
        };

        const noSpaceBelow = rect.control.top + rect.control.height + rect.target.height > rect.body.height;
        const noSpaceAbove = rect.control.top - rect.target.height < 0;
        const noSpaceRight = rect.control.left + rect.target.width > rect.body.width;
        const noSpaceLeft = rect.control.left + rect.control.width - rect.target.width < 0;

        if (noSpaceBelow && !noSpaceAbove) {
            offset.top = Math.round(-rect.target.height);
        }

        if (noSpaceAbove && noSpaceBelow) {
            target.style.height = Math.round(rect.body.height - rect.target.top - 20) + 'px';
        }

        if (noSpaceRight && !noSpaceLeft) {
            offset.left = Math.round(rect.control.width - rect.target.width);
        }

        return offset;
    }

    /**
     * @param {HTMLElement} control
     */
    _openDropdown(control) {
        this.activeControl = control;
        const target = this._getTarget(control);
        this.activeTarget = target;
        this.menuOptions = [...this.activeTarget.querySelectorAll('[role="menuitem"]')];

        requestAnimationFrame(() => {
            target.style.display = 'block';
            const offset = this._calcOffset(control, target);
            target.style.removeProperty('display');
            if (offset.top) {
                target.style.top = offset.top + 'px';
            }
            if (offset.left) {
                target.style.left = offset.left + 'px';
            }
            this.activeTarget.scrollTop = 0;
            control.setAttribute('aria-expanded', true);
            target.setAttribute('aria-hidden', false);
        });
    }

    /**
     * @param {HTMLElement} control
     */
    _closeDropdown(control) {
        if (!this.activeControl) {
            return;
        }
        const target = this._getTarget(control);

        this.activeControl.focus();
        this.activeControl = undefined;
        this.activeTarget = undefined;
        this.menuOptions = undefined;

        requestAnimationFrame(() => {
            control.setAttribute('aria-expanded', false);
            target.setAttribute('aria-hidden', true);
            target.removeAttribute('style');
        });
    }
}

export const dropdownInstance = new Dropdown();
